import { __read } from "tslib";
import * as React from 'react';
import { memo, useCallback, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import Contacts from './Contacts/Contacts';
import Passengers from './Passengers/Passengers';
import PassengersToolbar from './PassengersToolbar/PassengersToolbar';
import scrollToFirstErrorDecorator from './scrollToFirstErrorDecorator';
import { scrollTo } from '../../../../utils';
import { getIsPriceFreezeProductSelected } from '../../../store/order/selectors';
import { useSelector } from 'react-redux';
import Login from './Login/Login';
import { usePassengerForm } from '../../../../PassengerForm/hooks';
import FormStateWrapper from './FormStateWrapper/FormStateWrapper';
import { useToggleable } from '../../../../hooks';
import CheckDataModal from './CheckDataModal/CheckDataModal';
import { useModalXTweak } from '../../../../BaseComponents/ModalX/hooks';
export var Form = memo(function (_a) {
    var passengerClassName = _a.passengerClassName, currentStep = _a.currentStep, passengers = _a.passengers, customer = _a.customer, onFormSubmit = _a.onFormSubmit, isInternationalFlight = _a.isInternationalFlight, _b = _a.showForm, showForm = _b === void 0 ? true : _b, _c = _a.showContacts, showContacts = _c === void 0 ? true : _c, _d = _a.showServices, showServices = _d === void 0 ? true : _d, _e = _a.showToolbar, showToolbar = _e === void 0 ? true : _e, userInfo = _a.userInfo, refetchUser = _a.refetchUser, _f = _a.inModal, inModal = _f === void 0 ? false : _f, isChangePassengers = _a.isChangePassengers, allowSwapNames = _a.allowSwapNames, orderSubsidyState = _a.orderSubsidyState, backToResults = _a.backToResults, setNextStep = _a.setNextStep, passengersFormFirst = _a.passengersFormFirst, showCheckDataModal = _a.showCheckDataModal, renderToolbar = _a.renderToolbar, isChangeFiles = _a.isChangeFiles, onToggleChangeFiles = _a.onToggleChangeFiles;
    var formRef = useRef();
    var isFreezeOrder = useSelector(getIsPriceFreezeProductSelected);
    React.useEffect(function () {
        scrollTo(0);
    }, []);
    var _g = usePassengerForm(passengers, customer, isInternationalFlight, isFreezeOrder), initialValues = _g.state, selectedProfileIdsByPassenger = _g.selectedProfileIdsByPassenger, onFillValues = _g.onFillValues, onSwapNames = _g.onSwapNames;
    var _h = useToggleable(false), isOpen = _h.isOpen, open = _h.open, close = _h.close;
    useModalXTweak(open);
    var _j = __read(useState(null), 2), values = _j[0], setValues = _j[1];
    var onConfirm = useCallback(function () {
        onFormSubmit(values);
        close();
    }, [onFormSubmit, values]);
    var onFormSubmitModal = useCallback(function (values) {
        setValues(values);
        open();
    }, [open, setValues, values]);
    return (React.createElement(React.Fragment, null,
        React.createElement(FinalForm, { onSubmit: showCheckDataModal ? onFormSubmitModal : onFormSubmit, decorators: [scrollToFirstErrorDecorator], validateOnBlur: true, initialValues: initialValues, keepDirtyOnReinitialize: true, subscription: { submitErrors: true } }, function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (React.createElement(Login, { refetchUser: refetchUser, userInfo: userInfo }, function (_a) {
                var userInfo = _a.userInfo, refetchUser = _a.refetchUser, openLogin = _a.openLogin, isLoginFormOpen = _a.isLoginFormOpen;
                return (React.createElement("form", { ref: formRef, onSubmit: handleSubmit, noValidate: true },
                    showContacts && (React.createElement(Contacts, { userInfo: userInfo, refetchUser: refetchUser, submitForm: handleSubmit, customer: customer, onLoginOpen: openLogin })),
                    showForm && (React.createElement(Passengers, { className: passengerClassName, isAuthorized: !!(userInfo === null || userInfo === void 0 ? void 0 : userInfo.userProfile), isChangePassengers: isChangePassengers, onFill: onFillValues, fields: passengers, orderSubsidies: orderSubsidyState, backToResults: backToResults, selectedProfileIdsByPassenger: selectedProfileIdsByPassenger, showLoginForm: function () { return openLogin(''); }, userInfo: userInfo, refetchUser: refetchUser, isLoginFormOpen: isLoginFormOpen, isChangeFiles: isChangeFiles, onSwap: allowSwapNames ? onSwapNames : null, onToggleChangeFiles: onToggleChangeFiles })),
                    showToolbar && (React.createElement(React.Fragment, null, renderToolbar ? (React.createElement(FormStateWrapper, null, renderToolbar)) : (React.createElement(PassengersToolbar, { disableBooking: orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.isSubsidiesRejected, formRef: formRef, currentStep: currentStep, loading: orderSubsidyState === null || orderSubsidyState === void 0 ? void 0 : orderSubsidyState.isLoading, setNextStep: setNextStep }))))));
            }));
        }),
        showCheckDataModal && (React.createElement(CheckDataModal, { isOpen: isOpen, onClose: close, onConfirm: onConfirm, onCancel: close }))));
});
