import { __assign, __awaiter, __generator, __read } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { ApolloError } from 'apollo-boost';
import { CheckinOrderStatus, TravellerFieldEnum, useCheckinCompleteMutation, useGetCheckinOrderQuery } from '@websky/graphql';
import Passengers from './Passengers/Passengers';
import Stepbar from '../../../../../Stepbar';
import { getCheckinHasCompleted, getCheckinIsBlockedForPayment, getCheckinOrder, getIsAllTravellersHavePreseating, getIsContactsInfoEmpty, hasBaggageServices, hasMealServices, hasSeatMap, isExpired } from '../../../store/order/selectors';
import { CheckinStep, useCheckinAvailabilityInfo, useSteps } from '../../../utils';
import Meal from './routes/Meal';
import Baggage from './routes/Baggage';
import Payment from './routes/Payment';
import Extras from './routes/Extras';
import PaymentResult from '../../../../../PaymentResult';
import PaymentTimeLimitPage from '../../../../../PaymentTimeLimitPage';
import { setCheckinOrder } from '../../../store/order/actions';
import { useTheme } from '../../../../../theme';
import { CheckinGoal, reachGoal } from '../../../../../analytics';
import ProtectedRoute from '../../../../../ProtectedRoute/ProtectedRoute';
import { getUserValue, MOBILE_MIN_WIDTH, seatNumberRegex } from '../../../../../utils';
import CustomerContactsModal from '../../../../../CustomerContactsForm/Modal/CustomerContactsModal';
import { useCustomerContactsForm } from '../../../../../CustomerContactsForm/hooks';
import { getIsServicesSaving } from '../../../store/servicesLoading/selectors';
import SimpleLoader from '../../../../../SimpleLoader';
import PreselectedSeats from './PreselectedSeats/PreselectedSeats';
import WarningModal from '../../../../../WarningModal';
import { useUnlockCheckinOrder } from './hooks';
import { useToggleable } from '../../../../../hooks';
import MobileStepbar from '../../../../../MobileStepbar';
import { useCheckinOrderServices } from '../../../hooks';
import { useStepsToStepbarStepsAdapter } from './utils';
import { getFunnelParameters } from '../../../../../DataLayerAnalytics/utils';
var PaymentResultStatus;
(function (PaymentResultStatus) {
    PaymentResultStatus["Success"] = "success";
    PaymentResultStatus["Fail"] = "fail";
})(PaymentResultStatus || (PaymentResultStatus = {}));
var Order = function () {
    var t = useTranslation('Checkin').t;
    var css = useTheme('Checkin').Order;
    var isCheckinCompleted = useSelector(getCheckinHasCompleted);
    var isBlockedForPayment = useSelector(getCheckinIsBlockedForPayment);
    var hasBaggage = useSelector(hasBaggageServices);
    var hasMeals = useSelector(hasMealServices);
    var hasSeats = useSelector(hasSeatMap);
    var isCheckinExpired = useSelector(isExpired);
    var order = useSelector(getCheckinOrder);
    var isContactsInfoEmpty = useSelector(getIsContactsInfoEmpty);
    var isServicesSaving = useSelector(getIsServicesSaving);
    var isAllTravellersHavePreseating = useSelector(getIsAllTravellersHavePreseating);
    var isCheckinCompletedOrPreseating = isCheckinCompleted || isAllTravellersHavePreseating;
    var history = useHistory();
    var _a = __read(React.useState(null), 2), paymentResult = _a[0], setPaymentResult = _a[1];
    var _b = __read(React.useState(false), 2), orderRefetching = _b[0], setOrderRefetching = _b[1];
    var _c = __read(useState(), 2), requestError = _c[0], setRequestError = _c[1];
    var _d = useToggleable(false), isLoading = _d.isOpen, setIsLoading = _d.setOpen;
    var _e = useCustomerContactsForm(), saveClientInfo = _e.saveClientInfo, closeContactsModal = _e.closeContactsModal, openContactsModal = _e.openContactsModal, isContactsModalOpen = _e.isContactsModalOpen, isClientInfoSaving = _e.loading;
    var _f = useSteps(), goToNextStep = _f.goToNextStep, setStep = _f.setStep, getNextStep = _f.getNextStep, availableSteps = _f.availableSteps, previousStep = _f.previousStep, getNextStepEnum = _f.getNextStepEnum;
    var pathname = history.location.pathname;
    var handleReturn = useCallback(function () {
        history.push('/');
    }, []);
    var showExpireModal = useCheckinAvailabilityInfo(600000).showExpireModal;
    var getOrder = useGetCheckinOrderQuery({ skip: true, fetchPolicy: 'network-only' }).refetch;
    var _g = __read(useCheckinCompleteMutation(), 1), completeCheckin = _g[0];
    var isSeatsStep = pathname.includes(CheckinStep.Seats);
    var isPaymentStep = pathname.includes(CheckinStep.Payment);
    var saveClientInfoAndLoadOrder = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var getOrderResult, currentStep;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveClientInfo({ variables: __assign(__assign({}, data), { orderId: order.id }) })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, getOrder({ id: order.id })];
                case 2:
                    getOrderResult = (_a.sent()).data;
                    if (getOrderResult === null || getOrderResult === void 0 ? void 0 : getOrderResult.CheckinOrder) {
                        setOrder(getOrderResult.CheckinOrder);
                    }
                    closeContactsModal();
                    if (isPaymentStep) {
                        previousStep(CheckinStep.Payment);
                    }
                    else {
                        currentStep = isSeatsStep ? CheckinStep.Seats : CheckinStep.Extras;
                        goToNextStep(currentStep);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var dispatch = useDispatch();
    var setOrder = function (order) {
        dispatch(setCheckinOrder(order));
    };
    var onCloseWarningModal = function () {
        setRequestError(null);
    };
    var onRequestError = function (errors) {
        var _a, _b, _c, _d;
        var error = ((_b = (_a = errors === null || errors === void 0 ? void 0 : errors.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.debugMessage) || ((_d = (_c = errors === null || errors === void 0 ? void 0 : errors.graphQLErrors) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.message);
        var invalidSeatError = 'Invalid seat number';
        if (error.includes(invalidSeatError)) {
            setRequestError(t(invalidSeatError, { seatNumber: error.match(seatNumberRegex)[0] }));
        }
        else {
            setRequestError(error || 'Checkin error');
        }
    };
    var steps = useStepsToStepbarStepsAdapter(availableSteps);
    var getStepByPathname = function () {
        return Object.keys(steps).findIndex(function (stepKey) {
            return stepKey && pathname.includes(stepKey);
        });
    };
    var _h = __read(useState(getStepByPathname()), 2), activeStep = _h[0], setActiveStep = _h[1];
    useEffect(function () {
        var filteredSteps = {};
        if (!isCheckinCompleted && isBlockedForPayment && !isPaymentStep) {
            setStep(CheckinStep.Payment);
        }
        Object.keys(steps).forEach(function (stepKey) {
            if (steps.hasOwnProperty(stepKey) && !steps[stepKey].isHidden) {
                filteredSteps[stepKey] = steps[stepKey];
            }
        });
        if (!isCheckinCompleted && /^\/(\d+)\/?$/.test(pathname)) {
            setActiveStep(0); // passengers
        }
        else if (isCheckinCompleted) {
            setActiveStep(Object.keys(filteredSteps).length - 1); // boarding pass
        }
        else {
            setActiveStep(getStepByPathname());
        }
    }, [pathname, steps]);
    var onRefetchOrder = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getOrder({ id: order.id })];
                case 1:
                    data = (_b.sent()).data;
                    if (data.CheckinOrder) {
                        if (data.CheckinOrder.status !== CheckinOrderStatus.Started) {
                            throw new Error('Wrong order status!');
                        }
                        setOrder(data.CheckinOrder);
                    }
                    return [2 /*return*/, data.CheckinOrder];
                case 2:
                    e_1 = _b.sent();
                    if (e_1 instanceof ApolloError) {
                        if ((_a = e_1.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                            onRequestError(e_1);
                        }
                    }
                    else {
                        throw e_1;
                    }
                    return [2 /*return*/, null];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [order.id]);
    var _j = useCheckinOrderServices(onRefetchOrder, onRequestError, setStep), onDeleteItem = _j.onDeleteItem, cartLoading = _j.isLoading;
    var onProceed = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var currentStep, nextStep, checkinOrder, paymentIsRequired, isContactsInfoEmpty, _a, data, errors, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    currentStep = isSeatsStep ? CheckinStep.Seats : CheckinStep.Extras;
                    nextStep = getNextStepEnum(currentStep);
                    return [4 /*yield*/, onRefetchOrder()];
                case 1:
                    checkinOrder = _b.sent();
                    paymentIsRequired = checkinOrder === null || checkinOrder === void 0 ? void 0 : checkinOrder.paymentIsRequired;
                    isContactsInfoEmpty = checkinOrder === null || checkinOrder === void 0 ? void 0 : checkinOrder.customer.contactInfoIsEmpty;
                    if (!(nextStep === CheckinStep.Payment)) return [3 /*break*/, 6];
                    if (!paymentIsRequired) return [3 /*break*/, 2];
                    if (isContactsInfoEmpty) {
                        openContactsModal();
                    }
                    else {
                        setStep(CheckinStep.Payment);
                    }
                    return [3 /*break*/, 5];
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, completeCheckin({
                            variables: {
                                id: order.id
                            }
                        })];
                case 3:
                    _a = _b.sent(), data = _a.data, errors = _a.errors;
                    if (errors) {
                        console.error(errors);
                        onRequestError(errors);
                    }
                    else if (data === null || data === void 0 ? void 0 : data.CheckinComplete) {
                        setOrder(data.CheckinComplete);
                        setStep(CheckinStep.Passengers);
                    }
                    return [3 /*break*/, 5];
                case 4:
                    e_2 = _b.sent();
                    onRequestError(e_2);
                    console.error(e_2);
                    return [3 /*break*/, 5];
                case 5: return [3 /*break*/, 7];
                case 6:
                    goToNextStep(currentStep);
                    _b.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    }); }, [order.id, pathname, isContactsInfoEmpty]);
    var onCheckinStart = function () {
        goToNextStep(CheckinStep.Passengers);
    };
    var customerValues = React.useMemo(function () {
        var customer = order.customer;
        return {
            email: getUserValue(customer, TravellerFieldEnum.Email),
            phone: getUserValue(customer, TravellerFieldEnum.Phone)
        };
    }, [order.customer]);
    var refetchOrderAfterPayment = function () {
        var initialInterval = 500;
        var step = 1.5;
        setOrderRefetching(true);
        var polling = function (interval) { return __awaiter(void 0, void 0, void 0, function () {
            var newOrder, e_3;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getOrder({
                                id: order.id
                            })];
                    case 1:
                        newOrder = _c.sent();
                        if ([CheckinOrderStatus.Confirmed, CheckinOrderStatus.Expired].includes((_b = (_a = newOrder.data) === null || _a === void 0 ? void 0 : _a.CheckinOrder) === null || _b === void 0 ? void 0 : _b.status)) {
                            setOrder(newOrder.data.CheckinOrder);
                            setOrderRefetching(false);
                        }
                        else {
                            setTimeout(function () { return polling(interval * step); }, interval);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _c.sent();
                        setOrderRefetching(false);
                        console.warn(e_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        setTimeout(function () { return polling(initialInterval); }, initialInterval);
    };
    useEffect(function () {
        if (paymentResult === PaymentResultStatus.Success) {
            reachGoal(CheckinGoal.Paid);
            reachGoal(CheckinGoal.CompleteWithPayment, { funnel_checkin: getFunnelParameters(order) });
            if (isCheckinCompleted) {
                goToNextStep(CheckinStep.Passengers, true);
            }
            else {
                refetchOrderAfterPayment();
            }
        }
        else if (paymentResult === PaymentResultStatus.Fail) {
            reachGoal(CheckinGoal.PaymentCancelled);
            if (isCheckinCompleted) {
                goToNextStep(CheckinStep.Passengers, true);
            }
        }
    }, [paymentResult]);
    var unlockOrder = useUnlockCheckinOrder(setIsLoading);
    return (React.createElement("div", null,
        (isServicesSaving || orderRefetching || isLoading || cartLoading) && React.createElement(SimpleLoader, null),
        !showExpireModal && !isCheckinExpired && (React.createElement(React.Fragment, null,
            !isCheckinCompleted && (React.createElement(React.Fragment, null,
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Stepbar, { steps: steps, activeStep: activeStep, className: css.stepBar })),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    React.createElement(MobileStepbar, { order: order, currentStep: activeStep, stepbarConfig: steps, onServiceDelete: onDeleteItem })))),
            React.createElement(CustomerContactsModal, { initialValues: customerValues, onContactsSubmit: saveClientInfoAndLoadOrder, isLoading: isClientInfoSaving, open: isContactsModalOpen }),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/:id(\\d+)", exact: true, render: function () { return (React.createElement(Passengers, { onCheckinStart: onCheckinStart, onRefetchOrder: onRefetchOrder })); } }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/baggage", isAccessible: !isCheckinCompletedOrPreseating && hasBaggage, redirectTo: isCheckinCompletedOrPreseating
                        ? getNextStep(CheckinStep.Passengers)
                        : !hasBaggage
                            ? getNextStep(CheckinStep.Baggage)
                            : '', render: function () { return React.createElement(Baggage, null); } }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/meal", isAccessible: !isCheckinCompletedOrPreseating && hasMeals, redirectTo: isCheckinCompletedOrPreseating
                        ? getNextStep(CheckinStep.Passengers)
                        : !hasMeals
                            ? getNextStep(CheckinStep.Meal)
                            : '', render: function () { return React.createElement(Meal, { isLoading: isServicesSaving }); } }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/seats", isAccessible: !isCheckinCompletedOrPreseating && !!hasSeats, redirectTo: getNextStep(CheckinStep.Passengers), render: function () { return React.createElement(PreselectedSeats, { onProceed: onProceed }); } }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/extras", isAccessible: !isCheckinCompletedOrPreseating, redirectTo: getNextStep(CheckinStep.Passengers), render: function () { return React.createElement(Extras, { onProceed: onProceed, onSetOrder: setOrder }); } }),
                React.createElement(Route, { exact: true, path: "/:id(\\d+)/payment", render: function () {
                        if (isCheckinCompleted) {
                            goToNextStep(CheckinStep.Passengers, true);
                            return null;
                        }
                        if (isContactsInfoEmpty) {
                            openContactsModal();
                            return null;
                        }
                        setPaymentResult(null);
                        return React.createElement(Payment, null);
                    } }),
                React.createElement(Route, { path: "/:id(\\d+)/payment/successfull", render: function () {
                        setPaymentResult(PaymentResultStatus.Success);
                        return (React.createElement("div", { className: css.paymentResult },
                            React.createElement(PaymentResult, { result: "success", onClick: function () {
                                    setStep(CheckinStep.Passengers);
                                } })));
                    } }),
                React.createElement(Route, { path: "/:id(\\d+)/payment/failed", render: function () {
                        setPaymentResult(PaymentResultStatus.Fail);
                        return (React.createElement("div", { className: css.paymentResult },
                            React.createElement(PaymentResult, { result: "fail", onBackToOrder: unlockOrder, onClick: function () { return setStep(CheckinStep.Payment); } })));
                    } })))),
        (showExpireModal || isCheckinExpired) && (React.createElement("div", { className: css.timelimit__wrp },
            React.createElement(PaymentTimeLimitPage, { title: t('Sorry,'), text: t('online check-in is closed for this flight'), buttonText: t('OK'), onClick: handleReturn }))),
        React.createElement(WarningModal, { title: t('Oops, something went wrong'), content: t('An error occurred during the check-in process. Please try again later or contact a customer support service.'), isOpen: !!requestError, errorMessage: requestError, buttonText: t('Close'), onButtonClick: onCloseWarningModal })));
};
export default Order;
