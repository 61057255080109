if (!process.env.DOCKER_BUILD) {
    require('../../change-public-path');
}
import * as React from 'react';
import { render } from 'react-dom';
import { enableSentry } from '../../../utils';
enableSentry();
import SearchForm from './SearchForm';
import { withConfig } from '../../../config-hoc';
export var init = function (mount, config) {
    var Component = withConfig(SearchForm, config);
    render(React.createElement(Component, null), mount);
};
