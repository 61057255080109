import { __assign, __awaiter, __generator, __read, __spreadArray } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { TravellerFieldEnum, useOrderQuery, useSaveOrderServicesMutation, useFfpCompleteOrderMutation, OrderStatus } from '@websky/graphql';
import { getUserValue, MOBILE_MIN_WIDTH } from '../../../../../utils';
import { StepType } from '../../../../types';
import Toolbar from '../../../../../Toolbar';
import { useTheme } from '../../../../../theme';
import { isFormLocked } from '../../../../store/formIsLocked/selectors';
import MobileToolbar from '../../../../../MobileToolbar';
import { getBonusForFlight, getIsBooked, getIsBookedOrConfirmed, getIsCancelled, getIsConfirmedOrCancelled, getIsContactsInfoIsEmpty, getIsNew, getIsTravellersDataFilled, getOrder as getRootOrder, getOrderId, getPaymentIsRequired, getPriceInMiles, getSelectedInsuranceCodes, getServicesFallbackURL, getTotalInsurancePrice } from '../../../../store/order/selectors';
import { Money as MoneyComponent } from '../../../../../index';
import { useUpsaleWarning } from '../../Upsale/hooks';
import Warning from '../../Upsale/Warning/Warning';
import { getTotalPriceToPay } from '../../../../store/orderPrice/selectors';
import PassengersToolbarCheckbox from './PassengersToolbarCheckbox';
import { useCustomerContactsForm } from '../../../../../CustomerContactsForm/hooks';
import { fillOrder } from '../../../../store/order/actions';
import CustomerContactsModal from '../../../../../CustomerContactsForm/Modal/CustomerContactsModal';
import { useUpdateTravellers } from '../../../../../FareLock/hooks';
import { getPassengerState } from '../../../../store/passengers/selectors';
import { OverrideComponent } from '../../../../../renderProps';
import { InsuranceUpsaleModal } from '../../../../../InsurancePage';
import { useInsuranceUpsaleModal } from '../../../../../InsurancePage/components/InsuranceUpsaleModal/InsuranceUpsaleModal.hook';
import { saveOrderServicesParamsFactory } from '../../../../../InsurancePage/store/utils';
import AIPurchasePredictionService from '../../../../../AIPurchasePredictionService/components/AIPurchasePredictionService';
import { setMultipleSelectedServices } from '../../../../store/selectedServices/actions';
import { useAiPurchasePredictionService } from '../../../../../AIPurchasePredictionService/hook';
import { isServicesSaving } from '../../../../store/servicesIsSaving/selectors';
import { WEBSKY_SERVICE_OPEN_EVENT } from '../../../../constants';
import SimpleLoader from '../../../../../SimpleLoader';
var PassengersToolbar = function (_a) {
    var formRef = _a.formRef, currentStep = _a.currentStep, setNextStep = _a.setNextStep, disableBooking = _a.disableBooking, loading = _a.loading, onConfirmConsents = _a.onConfirmConsents;
    var t = useTranslation('Checkout').t;
    var _b = useTheme('Checkout'), checkoutTheme = _b.CheckoutStyles, toolbarTheme = _b.PassengersToolbar;
    var dispatch = useDispatch();
    var history = useHistory();
    var isNew = useSelector(getIsNew);
    var isBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    var isConfirmedOrCancelled = useSelector(getIsConfirmedOrCancelled);
    var isBooked = useSelector(getIsBooked);
    var formIsLocked = useSelector(isFormLocked);
    var totalInsurancePrice = useSelector(getTotalInsurancePrice);
    var totalPriceToPay = useSelector(getTotalPriceToPay);
    var servicesRedirectURL = useSelector(getServicesFallbackURL);
    var _c = useUpsaleWarning(), toggleWarningIfOrderConfirmed = _c.toggleWarningIfOrderConfirmed, showWarning = _c.showWarning, closeWarning = _c.closeWarning;
    var isOrderCancelled = useSelector(getIsCancelled);
    var bonusForFlight = useSelector(getBonusForFlight);
    var priceInMilesForFlight = useSelector(getPriceInMiles);
    var isPaymentRequired = useSelector(getPaymentIsRequired);
    var isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    var isContactsInfoEmpty = useSelector(getIsContactsInfoIsEmpty);
    var passengersValues = useSelector(getPassengerState);
    var order = useSelector(getRootOrder);
    var orderId = useSelector(getOrderId);
    var selectedInsuranceCodes = useSelector(getSelectedInsuranceCodes);
    var handleSubmit;
    var updateTravellers;
    var isOrderServicesSaving = useSelector(isServicesSaving);
    try {
        updateTravellers = useUpdateTravellers();
        handleSubmit = updateTravellers.handleSubmit;
    }
    catch (e) { }
    var isToolbarButtonDisabled = false;
    if (typeof updateTravellers !== 'undefined' && !updateTravellers.valid) {
        isToolbarButtonDisabled = true;
    }
    if (isTravellersDataFilled) {
        isToolbarButtonDisabled = false;
    }
    var _d = __read(useState(false), 2), servicesLoading = _d[0], setServicesLoading = _d[1];
    var getOrder = useOrderQuery({ skip: true }).refetch;
    var _e = __read(useSaveOrderServicesMutation(), 2), saveOrderServices = _e[0], saveOrderServicesResult = _e[1];
    var _f = __read(useFfpCompleteOrderMutation(), 2), ffpCompleteOrder = _f[0], ffpCompleteOrderResult = _f[1];
    var _g = useCustomerContactsForm(), saveClientInfo = _g.saveClientInfo, closeContactsModal = _g.closeContactsModal, openContactsModal = _g.openContactsModal, isContactsModalOpen = _g.isContactsModalOpen, isClientInfoSaving = _g.loading;
    var insuranceUpsaleModal = useInsuranceUpsaleModal({ strategy: 'checkout-before-payment' });
    var aiPurchasePredictionService = useAiPurchasePredictionService();
    var goToPayment = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, ffpCompleteOrderResult_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (isToolbarButtonDisabled) {
                        formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
                        return [2 /*return*/];
                    }
                    if (onConfirmConsents) {
                        onConfirmConsents();
                        return [2 /*return*/];
                    }
                    if (!handleSubmit) return [3 /*break*/, 2];
                    return [4 /*yield*/, handleSubmit(passengersValues.map(function (traveller) {
                            var _a;
                            return ({
                                id: traveller.id,
                                values: (_a = traveller.values) === null || _a === void 0 ? void 0 : _a.map(function (value) { return ({
                                    type: value.type,
                                    name: value.name,
                                    value: value.value
                                }); })
                            });
                        }))];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    if (showWarning) {
                        return [2 /*return*/, toggleWarningIfOrderConfirmed()];
                    }
                    if (isPaymentRequired && isContactsInfoEmpty) {
                        openContactsModal();
                        return [2 /*return*/];
                    }
                    if (!order.ffpInfo.skipPayment) return [3 /*break*/, 4];
                    return [4 /*yield*/, ffpCompleteOrder({ variables: { id: orderId } })];
                case 3:
                    result = _b.sent();
                    ffpCompleteOrderResult_1 = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.FfpCompleteOrder;
                    if ((ffpCompleteOrderResult_1 === null || ffpCompleteOrderResult_1 === void 0 ? void 0 : ffpCompleteOrderResult_1.result) && (ffpCompleteOrderResult_1 === null || ffpCompleteOrderResult_1 === void 0 ? void 0 : ffpCompleteOrderResult_1.order)) {
                        dispatch(fillOrder(ffpCompleteOrderResult_1.order));
                    }
                    return [3 /*break*/, 5];
                case 4:
                    setNextStep();
                    _b.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); }, [totalPriceToPay, passengersValues, onConfirmConsents, order.ffpInfo.skipPayment]);
    var onContinuePurchasePredictionService = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (onConfirmConsents) {
                        onConfirmConsents();
                        return [2 /*return*/];
                    }
                    setServicesLoading(true);
                    return [4 /*yield*/, aiPurchasePredictionService.onContinue(goToPayment)];
                case 1:
                    _a.sent();
                    setServicesLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [onConfirmConsents, goToPayment, aiPurchasePredictionService.onContinue]);
    var saveClientInfoAndUpdateOrder = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var getOrderResult;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveClientInfo({ variables: __assign(__assign({}, data), { orderId: orderId }) })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, getOrder({ id: orderId })];
                case 2:
                    getOrderResult = (_a.sent()).data;
                    if (getOrderResult === null || getOrderResult === void 0 ? void 0 : getOrderResult.Order) {
                        dispatch(fillOrder(getOrderResult.Order));
                    }
                    closeContactsModal();
                    setNextStep();
                    return [2 /*return*/];
            }
        });
    }); };
    var savePriorityInsurance = useCallback(function (selectedCodes) {
        if (selectedCodes === void 0) { selectedCodes = []; }
        return __awaiter(void 0, void 0, void 0, function () {
            var selectedCodesSet, travellerIds, saveOrderParams, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        selectedCodesSet = new Set(__spreadArray(__spreadArray([], __read(selectedInsuranceCodes), false), __read(selectedCodes), false));
                        if (!selectedCodesSet.size) {
                            return [2 /*return*/];
                        }
                        travellerIds = order.travellers.map(function (traveller) { return traveller.id; });
                        saveOrderParams = saveOrderServicesParamsFactory(travellerIds, __spreadArray([], __read(selectedCodesSet), false));
                        return [4 /*yield*/, saveOrderServices({
                                variables: {
                                    params: __assign(__assign({}, saveOrderParams.params), { id: order.id })
                                }
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        if (data === null || data === void 0 ? void 0 : data.SaveOrderServices) {
                            dispatch(fillOrder(data.SaveOrderServices));
                        }
                        return [4 /*yield*/, onContinuePurchasePredictionService()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }, [order, selectedInsuranceCodes, onContinuePurchasePredictionService, dispatch]);
    var saveAdditionalService = function (service) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(setMultipleSelectedServices([
                {
                    passengerId: service.passengerId,
                    allowedSegments: service.allowedSegments,
                    segmentId: service.segmentId,
                    serviceId: service.serviceId,
                    count: service.count,
                    service: {
                        id: service.service.id,
                        type: service.service.type
                    }
                }
            ]));
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        if (!isOrderServicesSaving && aiPurchasePredictionService.isAddedService) {
            goToPayment();
        }
    }, [isOrderServicesSaving, aiPurchasePredictionService.isAddedService]);
    var onChangeStep = function (service) {
        var event = new CustomEvent(WEBSKY_SERVICE_OPEN_EVENT, {
            detail: { service: service, callback: goToPayment }
        });
        document.dispatchEvent(event);
    };
    var customerValues = React.useMemo(function () {
        var customer = order.customer;
        return {
            email: getUserValue(customer, TravellerFieldEnum.Email),
            phone: getUserValue(customer, TravellerFieldEnum.Phone)
        };
    }, [order.customer]);
    var goBack = useCallback(function () {
        history.goBack();
    }, [history]);
    var hasPriceOrMilesToPay = totalPriceToPay.amount > 0 || (order.ffpInfo.skipPayment && order.status !== OrderStatus.Confirmed);
    var showToolbar = !formIsLocked && (currentStep === null || currentStep === void 0 ? void 0 : currentStep.type) !== StepType.Payment && !isOrderCancelled && hasPriceOrMilesToPay;
    var isDesktopToolbarLoading = (updateTravellers === null || updateTravellers === void 0 ? void 0 : updateTravellers.loading) || loading || saveOrderServicesResult.loading || servicesLoading;
    var isMobileToolbarDisabled = disableBooking || loading || saveOrderServicesResult.loading || servicesLoading;
    return (React.createElement(React.Fragment, null,
        ffpCompleteOrderResult.loading && React.createElement(SimpleLoader, null),
        showToolbar && (React.createElement(React.Fragment, null,
            React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
            React.createElement(InsuranceUpsaleModal, { insurance: insuranceUpsaleModal.priorityInsurance, isOpen: insuranceUpsaleModal.isUpsaleOpen, onAgree: function () { return insuranceUpsaleModal.onAgree(savePriorityInsurance); }, onDisagree: function () { return insuranceUpsaleModal.onDisagree(onContinuePurchasePredictionService); } }),
            React.createElement(AIPurchasePredictionService, { order: order, service: aiPurchasePredictionService.service, onAgree: function (service) { return aiPurchasePredictionService.onAgree(service, saveAdditionalService); }, onChange: function (payload) { return aiPurchasePredictionService.onChange(payload, onChangeStep); }, onDisagree: function () { return aiPurchasePredictionService.onDisagree(goToPayment); } }),
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(Toolbar, { isLoading: isDesktopToolbarLoading, actionsRight: isBooked, hideNextButton: !hasPriceOrMilesToPay, totalPrice: totalPriceToPay, priceInMiles: !isConfirmedOrCancelled && priceInMilesForFlight, onButtonClick: isBookedOrConfirmed
                        ? function () { return insuranceUpsaleModal.onContinue(onContinuePurchasePredictionService); }
                        : null, miles: !isConfirmedOrCancelled && bonusForFlight, buttonDisabled: disableBooking, additional: React.createElement(React.Fragment, null,
                        isBooked && totalInsurancePrice && totalInsurancePrice.amount && (React.createElement("div", { className: checkoutTheme.gdsTotalPrice },
                            t('Insurance'),
                            ": ",
                            React.createElement(MoneyComponent, { money: totalInsurancePrice }))),
                        React.createElement("div", null,
                            isNew && React.createElement(PassengersToolbarCheckbox, null),
                            React.createElement(OverrideComponent, { componentProps: {
                                    order: order
                                }, component: {
                                    renderPassengersToolbarAdditional: function () { return null; }
                                } }))) })),
            React.createElement(CustomerContactsModal, { initialValues: customerValues, onContactsSubmit: saveClientInfoAndUpdateOrder, isLoading: isClientInfoSaving, open: isContactsModalOpen, onClose: closeContactsModal }),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: toolbarTheme.toolbarCheckbox__wrapper },
                    isNew && React.createElement(PassengersToolbarCheckbox, null),
                    React.createElement(OverrideComponent, { componentProps: {
                            order: order
                        }, component: {
                            renderPassengersToolbarAdditional: function () { return null; }
                        } })),
                React.createElement(MobileToolbar, { onBack: goBack, disabled: isMobileToolbarDisabled, onContinue: isBookedOrConfirmed && hasPriceOrMilesToPay
                        ? function () { return insuranceUpsaleModal.onContinue(onContinuePurchasePredictionService); }
                        : isBookedOrConfirmed
                            ? undefined
                            : null }))))));
};
export default PassengersToolbar;
